<template>
  <div class="card card-custom gutter-b">
    <div class="col-md-12">
      <b-form @submit.prevent.stop="onSubmitForm">
        <div class="card-header">
          <h4 class="title-card-custom">Tambah Data {{ pilihans }}</h4>
        </div>
        <div class="card-body">
          <b-form-group
            id="direktorat"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Direktorat / Sekretariat / Kedeputian"
            label-for="direktorat"
            description="Silakan pilih Lainnya untuk tambah direktorat"
          >
            <b-form-select
              v-model="form.directorate"
              :options="directorates"
              @change="onSelectDirectorate"
              class="mb-3"
              required
            >
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Pilih salah satu --</b-form-select-option
                >
              </template>
            </b-form-select>

            <b-input-group
              id="input-group-direktorat"
              class="mt-3"
              v-if="form.directorate === 9"
              v-show="show ? hidden : !show"
            >
              <b-form-input
                id="input-2"
                v-model="form.others"
                required
                placeholder="Direktorat"
              ></b-form-input>
              <b-input-group-append>
                <button
                  class="btn btn-submit"
                  @click.prevent.stop="addDirectorate()"
                >
                  Submit
                </button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            id="subdirectorate"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Subdirektorat / Bidang / Bagian"
            label-for="subdirectorate"
            description="Silakan klik tambah untuk tambah subdirektorat"
          >
            <b-form-select
              v-model="form.subdirectorate"
              :options="subdirectorates"
              class="mb-3"
              required
            >
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Pilih salah satu --</b-form-select-option
                >
              </template>
            </b-form-select>

            <b-input-group id="input-group-subdirektorat" class="mt-3">
              <b-form-input
                id="input-2"
                v-model="form.add_subdirectorate"
                required
                placeholder="Subdirektorat"
                :disabled="!isEditing"
                :class="{ view: !isEditing }"
              ></b-form-input>
              <b-input-group-append>
                <button
                  class="btn btn-ubah"
                  @click.prevent.stop="isEditing = !isEditing"
                  v-if="!isEditing"
                >
                  Tambah
                </button>
                <button
                  @click.prevent.stop="isEditing = false"
                  class="btn btn-ubah"
                  v-else-if="isEditing"
                  @click="resetSubDirectorate"
                >
                  Batal
                </button>
                <button
                  @click.prevent.stop="addSubDirectorate"
                  class="btn btn-submit"
                  :disabled="!isEditing"
                >
                  Submit
                </button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            id="produk"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Judul"
            label-for="produk"
          >
            <b-form-textarea
              id="textarea"
              v-model="form.name"
              placeholder="Judul"
              rows="3"
              max-rows="6"
              required
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
            id="produk"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Tahun"
            label-for="tahun"
          >
            <b-form-input
              id="tahun"
              v-model="form.tahun"
              type="text"
              placeholder="Tahun"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-6"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Target"
            label-for="input-6"
          >
            <b-form-datepicker
              id="example-datepicker"
              v-model="form.date"
              class="mb-2"
              placeholder="Pilih Target"
              locale="id"
              required
            ></b-form-datepicker>
          </b-form-group>

          <b-form-group
            id="input-group-4"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Progres"
            label-for="input-progres"
          >
            <b-form-textarea
              id="input-progres"
              v-model="form.progres"
              placeholder="Progres"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
            id="input-group-6"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Tanggal Progress"
            label-for="tanggal-progress"
          >
            <b-form-datepicker
              id="tanggal-progress"
              v-model="form.tanggal_progress"
              class="mb-2"
              placeholder="Pilih Tanggal Progress"
              locale="id"
              required
            ></b-form-datepicker>
          </b-form-group>

          <b-form-group
            id="pelaporan"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Pelaporan"
            label-for="pelaporan"
          >
            <b-form-textarea
              id="textarea"
              v-model="form.pelaporan"
              placeholder="Pelaporan"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
            id="keterangan"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Keterangan"
            label-for="keterangan"
          >
            <b-form-select
              v-model="form.keterangan"
              :options="keterangans"
              class="mb-3"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Pilih salah satu --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-7"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Upload Produk"
            label-for="input-7"
            description="Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)"
          >
            <b-form-file
              placeholder="Pilih File"
              drop-placeholder="Drop file di sini..."
              accept=".pdf, .doc, .docx"
              v-model="form.path"
              browse-text="Cari"
              multiple
              :file-name-formatter="formatNames"
              @change="
                validateFileTypeGeneral($event);
                fileSizeChecker('10', $event)"
              required
            ></b-form-file>
          </b-form-group>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-3">
              <button
                class="btn btn-secondary"
                type="reset"
                style="border-radius: 3px;"
                @click="$router.back()"
              >
                Batal
              </button>
              <button class="ml-2 btn btn-submit" type="submit">Kirim</button>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { handleErrors, randomCode } from "@/core/appUtil/util";
import Swal from "sweetalert2";
import moment from "moment";
import {
  fileSizeChecker,
  validateFileTypeGeneral,
} from "@/core/helpers/functions";

let token = "";

const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-save",
    cancelButton: "btn btn-delete"
  },
  buttonsStyling: false
});

export default {
  mixins: [fileSizeChecker, validateFileTypeGeneral],
  name: "FormTambah",
  data() {
    return {
      form: {
        subdirectorate: null,
        add_subdirectorate: "",
        others: "",
        pelaporan: "",
        name: "",
        date: "",
        tahun: "",
        tanggal_progress: "",
        path: null,
        id: null,
        directorate: null,
        progres: "",
        keterangan: null
      },
      new_id: null,
      keterangans: [
        {
          value: "Proleg",
          text: "Proleg"
        },
        {
          value: "Non Proleg",
          text: "Non Proleg"
        },
        {
          value: "Lainnya",
          text: "Lainnya"
        }
      ],
      directorates: [],
      subdirectorates: [],
      pilihans: [],
      show: false,
      hidden: false,
      isEditing: false
    };
  },
  mounted() {
    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;

      this.setJenisPerundangan();

      this.getMasterDirectorate();
      this.onSelectDirectorate();
    });
  },
  methods: {
    setJenisPerundangan(attempt = 0) {
      attempt++;

      let jenisId = parseInt(this.$route.params.jenisId);

      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL +
            `/master/jenis-perundangan/${jenisId}`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(response => response.json())
        .then(items => {
          if (items.status != 'error') {
            this.pilihans = items.data.name;

          } else {
            this.$nextTick(() => {
              if (attempt < 3) {
                this.setJenisPerundangan(attempt);
              } else {
                Swal.fire({
                  position: "center",
                  icon: 'error',
                  title: "Jenis Perundang-Undangan tidak ditemukan",
                  showConfirmButton: false,
                  timer: 1500
                })
                .then(() => {
                  this.$router.push({ name: "TabelUndangUndang" });
                });
              }

            });
          }
          // this.form.pilihan = jenisQ;
        })
    },
    getMasterDirectorate() {
      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL + `/master/direktorat?take=100`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          const detailMenu = items.data;
          let filtered = detailMenu ? detailMenu.filter(member => member.name !== "N/A") : [];
          let selectedOptions;
          selectedOptions = [];

          if (items.data !== null) {
            filtered.map(i => {
              return selectedOptions.push({
                text: i.name,
                value: i.id
              });
            });
            this.directorates = selectedOptions;

          } else {
            this.getMasterDirectorate();
          }
        });
    },
    addDirectorate() {
      let code = randomCode(2);
      let data = {
        id: null,
        name: this.form.others,
        code: code
      };

      swalSuccess.fire({
        title: "Tambah Direktorat",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(process.env.VUE_APP_URL_LOCAL + `/master/direktorat`),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              },
              body: JSON.stringify(data)
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              Swal.fire({
                position: "center",
                icon: data.status,
                title: "Data berhasil ditambah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetForm();
              this.onSelectDirectorate();
              this.getMasterDirectorate();
            })
            .catch(error => {
              console.error(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Data yang ditambah sudah ada",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    addSubDirectorate() {
      if (this.form.directorate == null) {
        Swal.fire({
          title: "Kesalahan",
          text: "Pilih direktorat terlebih dahulu",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "Kembali",
          confirmButtonColor: "#18328D"
        });

        return;
      }

      let code = randomCode(2);
      let data = {
        id: null,
        name: this.form.add_subdirectorate,
        code: code,
        direktorat_id: this.form.directorate
      };

      swalSuccess.fire({
        title: "Tambah Sub Direktorat",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(process.env.VUE_APP_URL_LOCAL + `/master/subdirektorat`),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              },
              body: JSON.stringify(data)
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              Swal.fire({
                position: "center",
                icon: data.status,
                title: "Data berhasil ditambah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetSubDirectorate();
              this.isEditing = false;
              this.onSelectDirectorate();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Data yang ditambah sudah ada",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    async onSelectDirectorate() {
      if (this.form.directorate == null) {
        return;
      }

      await fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL +
            `/master/subdirektorat?direktorat_id=${this.form.directorate}`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          const detailMenu = items.data;
          let selectedOptions;
          selectedOptions = [];

          if (items.data !== null) {
            detailMenu.map(i => {
              return selectedOptions.push({
                text: i.name,
                value: i.id
              });
            });
            this.subdirectorates = selectedOptions;

          } else {
            this.onSelectDirectorate();
          }
        });
    },
    onSubmitForm() {
      let data = {
        direktorat_id: this.form.directorate,
        id: this.form.id,
        jenis_perundangan_id: parseInt(this.$route.params.jenisId),
        keterangan: this.form.keterangan,
        nama: this.form.name,
        tahun: parseInt(this.form.tahun),
        subdirektorat_id: this.form.subdirectorate,
        target: this.form.date ? moment.utc(this.form.date, "YYYY-MM-DD").format() : null
      };

      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          await fetch(
            encodeURI(process.env.VUE_APP_URL_LOCAL + `/produk-hukum`),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              },
              body: JSON.stringify(data)
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(async data => {
              this.new_id = data.data.id;
              if (this.form.path !== null) {
                let dataUpload = new FormData();
                for (const file of this.form.path) {
                  dataUpload.append("files", file, file.name);
                }
                await fetch(
                  encodeURI(
                    process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`
                  ),
                  {
                    method: "POST",
                    headers: {
                      Authorization: "bearer " + token
                    },
                    body: dataUpload
                  }
                )
                  .then(handleErrors)
                  .then(response => response.json())
                  .then(async dataHasilUpload => {
                    let produkHukumProgress = {
                      produk_hukum_id: data.data.id,
                      path: dataHasilUpload.data.join(";"),
                      laporan: this.form.pelaporan,
                      tanggal: this.form.tanggal_progress ? moment
                        .utc(this.form.tanggal_progress, "YYYY-MM-DD")
                        .format() : null,
                      judul: this.form.progres
                    };
                    await fetch(
                      encodeURI(
                        process.env.VUE_APP_URL_LOCAL + `/produk-hukum-progress`
                      ),
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "bearer " + token
                        },
                        body: JSON.stringify(produkHukumProgress)
                      }
                    )
                      .then(handleErrors)
                      .then(response => response.json())
                      .then(dataHasilProgress => {
                        Swal.fire({
                          position: "center",
                          icon: dataHasilProgress.status,
                          title: "Data berhasil ditambahkan",
                          showConfirmButton: false,
                          timer: 1500
                        });
                        this.onReset();
                        this.$router.push({
                          name: "TabelUndangUndang"
                        });
                      });
                  });
              } else {
                let produkHukumProgress = {
                  produk_hukum_id: this.new_id,
                  laporan: this.form.pelaporan,
                  tanggal: this.form.tanggal_progress ? moment
                    .utc(this.form.tanggal_progress, "YYYY-MM-DD")
                    .format() : null,
                  judul: this.form.progres
                };
                await fetch(
                  encodeURI(
                    process.env.VUE_APP_URL_LOCAL + `/produk-hukum-progress`
                  ),
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "bearer " + token
                    },
                    body: JSON.stringify(produkHukumProgress)
                  }
                )
                  .then(handleErrors)
                  .then(response => response.json())
                  .then(dataHasilProgress => {
                    Swal.fire({
                      position: "center",
                      icon: dataHasilProgress.status,
                      title: "Data berhasil ditambahkan",
                      showConfirmButton: false,
                      timer: 1500
                    });
                    this.onReset();
                    this.$router.push({
                      name: "TabelUndangUndang"
                    });
                  });
              }
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal menambahkan data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    resetForm() {
      this.form.others = "";
    },
    resetSubDirectorate() {
      this.form.add_subdirectorate = "";
    },
    onReset() {
      // Reset our form values
      this.form.name = "";
      this.form.tahun = "";
      this.form.keterangan = null;
      this.form.others = "";
      this.form.progres = "";
      this.form.pelaporan = "";
      this.form.date = "";
      this.form.tanggal_progress = "";
      this.form.path = null;
      this.form.directorate = null;
      this.form.subdirectorate = null;
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} file terpilih`;
    }
  }
};
</script>

<style scoped>
.btn-submit {
  background: #18328d;
  border-radius: 3px;
  color: #ffffff;
}
.btn-submit:hover {
  background: #3851ab;
}
.view {
  color: initial;
}
.show {
  visibility: visible;
}
.hide {
  visibility: hidden;
}
</style>
